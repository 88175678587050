import React, {useCallback, useContext, useEffect} from 'react'
import {
  FormularioOrden,
  ConfirmacionOrden,
  CargaOrdenesMasiva,
} from '../../componentes/Ordenes'
import {useLocation} from 'react-router-dom'
import {ContextoOrden, ContextoAplicacion} from '../../contexto'
import {
  ObtenerOrdenPorId,
  FachadaActualizarOrden,
  ObtenerServicioPorId,
  ObtenerTarifarioPorEmpresaId,
} from '../../microservicios'
import {
  ObtenerCiudadPorNombre,
  AgregarPaquetesACache,
  AgregarServiciosACache,
} from '../../cache/servicios-cache'
import {useMsal} from '@azure/msal-react'
import {useHistory} from 'react-router-dom'
import moment from 'moment'
import {ESTADOS_ORDENES} from '../../constantes'

const EditarOrden = ({}) => {
  const {state} = useLocation()
  const contextoOrden = useContext(ContextoOrden)
  const contextoAplicacion = useContext(ContextoAplicacion)
  const history = useHistory()

  const {accounts} = useMsal()
  const idEmpresa = accounts[0].idTokenClaims.city

  const obtenerInformacionOrden = useCallback(async (idOrden) => {
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
    let tarifario = (await ObtenerTarifarioPorEmpresaId(idEmpresa)).data
    contextoOrden.setTarifario(tarifario)
    await ObtenerOrdenPorId(idOrden).then(async (res) => {
      contextoOrden.setFormularioOrden(res)
      contextoOrden.setDescontarSaldo(res.descontarSaldoPortal)
      let listaServicios = await Promise.all(
        res?.servicios?.map(async (x) => ({
          Id: x?.Id,
          Nombre:
            tarifario?.servicios?.find((y) => y?.servicioId == x?.Id)?.alias ??
            x?.Nombre,
          Tipo: 'Servicio',
          Observacion: (await ObtenerServicioPorId(x?.Id))?.data?.observaciones,
        })) ?? []
      )

      let listaPaquetes = await Promise.all(
        res.paquetes.map(async (x) => ({
          Id: x?.Id,
          Nombre: x?.Nombre,
          Tipo: 'Paquete',
          ServiciosPaquete: await Promise.all(
            x?.Servicios?.map(async function (x) {
              let servicio = (await ObtenerServicioPorId(x))?.data
              return {
                Id: servicio?.id,
                Nombre: servicio?.nombre,
                Observacion: servicio?.observaciones,
              }
            })
          ),
        })) ?? []
      )
      contextoOrden.setExamenesSeleccionados(
        listaServicios.concat(listaPaquetes)
      )
      contextoOrden.setActualizar(!contextoOrden.actualizar)
      contextoOrden.setEstadoOrden(state.state.estado)
    })
  }, [])

  useEffect(() => {
    obtenerInformacionOrden(state.state.id).catch(console.error)
  }, [obtenerInformacionOrden])

  const transformarNumero = (numero) => {
    if (parseInt(numero) < 10) {
      return `0${numero}`
    } else {
      return numero
    }
  }

  const manejarEnviarOGuardar = async (estadorOrden) => {
    let ciudad = (
      await ObtenerCiudadPorNombre(contextoOrden.formularioOrden.ciudad)
    )?.Id
    let payloadOrden = {
      id: state.state.id,
      empresaId: idEmpresa,
      tarifarioId: contextoOrden.tarifario.id,
      fechaCreacion: contextoOrden.formularioOrden.fechaCreacion,
      fechaAtencion:
        contextoOrden.formularioOrden.horaSolicitadaAtencion &&
        contextoOrden.formularioOrden.horaSolicitadaAtencion !== '00:00'
          ? moment(contextoOrden.formularioOrden.fechaSolicitudAtencion).format(
              'YYYY-MM-DD'
            ) +
            ' ' +
            contextoOrden.formularioOrden.horaSolicitadaAtencion
          : moment(contextoOrden.formularioOrden.fechaSolicitudAtencion).format(
              'YYYY-MM-DD'
            ),
      fechaVencimiento: contextoOrden.formularioOrden.fechaVencimientoOrden,
      ciudadId: ciudad,
      usuario: {
        tipoDocumento: contextoOrden.formularioOrden.tipoDeDocumento,
        numeroDocumento: contextoOrden.formularioOrden.numeroDocumento,
        nombres: contextoOrden.formularioOrden.nombre,
        apellidos: contextoOrden.formularioOrden.apellido,
        celular: contextoOrden.formularioOrden.celular,
        correo: contextoOrden.formularioOrden.correo,
        tipoUsuario: 1,
        cargo: contextoOrden.formularioOrden.cargo,
        indicativo: contextoOrden.indicativo,
      },
      tipoExamenId: contextoOrden.formularioOrden.tipoExamen,
      observacionesMedicas: contextoOrden.formularioOrden.observacionesMedicas
        ? contextoOrden.formularioOrden.observacionesMedicas.toUpperCase()
        : '',
      servicios: contextoOrden.examenesSeleccionados
        .filter((x) => x.Tipo == 'Servicio')
        .map((x) => x.Id),
      paquetes: contextoOrden.examenesSeleccionados
        .filter((x) => x.Tipo == 'Paquete')
        .map((x) => x.Id),
      estadoOrdenId: estadorOrden,
      empresaHijaId:
        contextoOrden.formularioOrden.empresaHijaId === '-1'
          ? ''
          : contextoOrden.formularioOrden.empresaHijaId ?? '',
      DescontarSaldoPortal: contextoOrden.descontarSaldo,
    }
    await FachadaActualizarOrden(payloadOrden).then((res) => {
      if (res.status == 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'DATOS ACTUALIZADOS',
          contenido: (
            <ConfirmacionOrden
              textoAdicional={`${
                contextoOrden.descontarSaldo &&
                estadorOrden === ESTADOS_ORDENES.ABIERTA
                  ? 'ESTA ORDEN SERA DESCONTADA DE SU SALDO, POR FAVOR TENER SALDO DISPONIBLE AL MOMENTO DE LA ATENCIÓN'
                  : ''
              }`}
              texto={`NÚMERO DE ORDEN: ${state.state.id}`}
            />
          ),
          botones: [
            {
              nombre: 'Regresar a órdenes',
              click: manejarVolverAOrdenes,
            },
            {
              nombre: 'Finalizar',
              click: manejarCerrarModalConfirmacion,
            },
          ],
        })
      }
    })
  }

  const manejarCerrarModalConfirmacion = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/home')
  }
  const manejarVolverAOrdenes = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/ordenes')
  }

  return (
    <div className="w-full pt-10 pb-10">
      <div className="container mx-auto flex justify-center">
        <div className="w-9/12 flex">
          <div className="w-9/12">
            <h4
              className="text-center text-cendiatra text-3xl font-bold"
              style={{paddingLeft: 235}}
            >
              EDITAR ORDEN
            </h4>
          </div>
          <div className="w-3/12"></div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="w-full">
          <FormularioOrden
            manejarEnviar={() => manejarEnviarOGuardar(ESTADOS_ORDENES.ABIERTA)}
            manejarGuardar={() =>
              manejarEnviarOGuardar(ESTADOS_ORDENES.BORRADOR)
            }
            modoEdicion={true}
          />
        </div>
      </div>
    </div>
  )
}

export default EditarOrden
