export const ESTADOS_ORDENES = {
  BORRADOR: 1,
  ABIERTA: 2,
  EN_PROCESO: 3,
  ATENDIDA: 4,
  CANCELADA: 5,
  VENCIDA: 6,
}

export const ESTADOS_ORDENES_STRING = {
  BORRADOR: 'BORRADOR',
  ABIERTA: 'ABIERTA',
  EN_PROCESO: 'EN_PROCESO',
  ATENDIDA: 'ATENDIDA',
  CANCELADA: 'CANCELADA',
  VENCIDA: 'VENCIDA',
}
