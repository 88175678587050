import {getRestApi} from './ApiAxios'

export const ObtenerEmpresaPorId = async (id) => {
  const restApi = await getRestApi()
  return await restApi.get('gato-api-function/ObtenerEmpresaPorId', {
    params: {id},
  })
}

export const ObtenerEmpresasDB = async (fechaUltimaActualizacion) => {
  const restApi = await getRestApi()
  return await restApi.get('gato-api-function/ObtenerEmpresas', {
    params: {fecha: fechaUltimaActualizacion ? fechaUltimaActualizacion : ''},
  })
}
