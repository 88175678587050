import {getRestApi} from './ApiAxios'

export const ObtenerConfiguracionPorParticion = async (particion) => {
  const apiReq = await getRestApi()
  return await apiReq({
    method: 'GET',
    url: `gato-api-function/ObtenerConfiguracionPorParticion`,
    params: {particion},
  })
}
