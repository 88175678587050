import React, {useEffect, useContext, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {useMsal} from '@azure/msal-react'
import {
  ConfirmacionOrden,
  FormularioOrden,
  CargaOrdenesMasiva,
} from '../../componentes/Ordenes'
import {ContextoAplicacion, ContextoOrden} from '../../contexto'
import {
  CrearNuevaOrdenFachada,
  ObtenerTarifarioPorEmpresaId,
} from '../../microservicios'
import {
  ObtenerCiudadPorNombre,
  AgregarServiciosACache,
  AgregarPaquetesACache,
} from '../../cache/servicios-cache'
import moment from 'moment'
import {BotonSimple} from '../../componentes/Botones'
import descargarPlantilla from '../../imagenes/DescargarPlantilla.svg'
import {RUTAS_APLICACION} from '../../constantes'

const CrearOrden = ({}) => {
  const contextoOrden = useContext(ContextoOrden)
  const contextoAplicacion = useContext(ContextoAplicacion)

  const history = useHistory()

  const {accounts} = useMsal()
  const idEmpresa = accounts[0].idTokenClaims.city

  const obtenerInformacionOrden = useCallback(async () => {
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
    contextoOrden.setTarifario(
      (await ObtenerTarifarioPorEmpresaId(idEmpresa)).data
    )
  }, [])

  useEffect(() => {
    contextoOrden.setEstadoOrden('')
    obtenerInformacionOrden().catch(console.error)
  }, [obtenerInformacionOrden])

  const ObtenerPayload = async (estadoOrden) => {
    let ciudad = (
      await ObtenerCiudadPorNombre(contextoOrden.formularioOrden.ciudad)
    )?.Id
    let payloadOrden = {
      tarifarioId: contextoOrden.tarifario.id,
      fechaCreacion: contextoOrden.formularioOrden.fechaCreacion,
      fechaAtencion: contextoOrden.formularioOrden.horaSolicitadaAtencion
        ? moment(contextoOrden.formularioOrden.fechaSolicitudAtencion).format(
            'YYYY-MM-DD'
          ) +
          ' ' +
          contextoOrden.formularioOrden.horaSolicitadaAtencion
        : moment(contextoOrden.formularioOrden.fechaSolicitudAtencion).format(
            'YYYY-MM-DD'
          ),
      fechaVencimiento: contextoOrden.formularioOrden.fechaVencimientoOrden,
      ciudadId: ciudad,
      usuario: {
        tipoDocumento: contextoOrden.formularioOrden.tipoDeDocumento,
        numeroDocumento: contextoOrden.formularioOrden.numeroDocumento,
        nombres: contextoOrden.formularioOrden.nombre,
        apellidos: contextoOrden.formularioOrden.apellido.toUpperCase(),
        celular: contextoOrden.formularioOrden.celular,
        correo: contextoOrden.formularioOrden.correo.toUpperCase(),
        tipoUsuario: 1,
        cargo: contextoOrden.formularioOrden.cargo.toUpperCase(),
        indicativo: contextoOrden.indicativo,
      },
      tipoExamenId: contextoOrden.formularioOrden.tipoExamen,
      observacionesMedicas: contextoOrden.formularioOrden.observacionesMedicas
        ? contextoOrden.formularioOrden.observacionesMedicas.toUpperCase()
        : '',
      servicios: contextoOrden.examenesSeleccionados
        .filter((x) => x.Tipo == 'Servicio')
        .map((x) => x.Id),
      paquetes: contextoOrden.examenesSeleccionados
        .filter((x) => x.Tipo == 'Paquete')
        .map((x) => x.Id),
      estadoOrdenId: estadoOrden,
      empresaId: idEmpresa,
      empresaHijaId:
        contextoOrden.formularioOrden.empresaHijaId === '-1'
          ? ''
          : contextoOrden.formularioOrden.empresaHijaId ?? '',
      DescontarSaldoPortal: contextoOrden.descontarSaldo,
    }
    return payloadOrden
  }

  const manejarEnviar = async () => {
    let payloadOrden = await ObtenerPayload(2)
    await CrearNuevaOrdenFachada(payloadOrden).then((res) => {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: 'ORDEN CREADA',
        contenido: (
          <ConfirmacionOrden
            textoAdicional={`${
              contextoOrden.descontarSaldo
                ? 'ESTA ORDEN SERA DESCONTADA DE SU SALDO, POR FAVOR TENER SALDO DISPONIBLE AL MOMENTO DE LA ATENCIÓN'
                : ''
            }`}
            texto={`NÚMERO DE ORDEN: ${transformarNumero(res.data)}`}
          />
        ),
        botones: [
          {
            nombre: 'Crear Nuevo',
            click: manejarCrearNuevaOrden,
          },
          {
            nombre: 'Regresar a órdenes',
            click: manejarVolverAOrdenes,
          },
          {
            nombre: 'Finalizar',
            click: manejarCerrarModalConfirmacion,
          },
        ],
      })
    })
  }

  const manejarGuardar = async () => {
    let payloadOrden = await ObtenerPayload(1)
    await CrearNuevaOrdenFachada(payloadOrden).then((res) => {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: 'ORDEN GUARDADA COMO BORRADOR',
        contenido: <ConfirmacionOrden texto={''} />,
        botones: [
          {
            nombre: 'Volver a órdenes',
            click: manejarVolverAOrdenes,
          },
        ],
      })
    })
  }

  const transformarNumero = (numero) => {
    if (parseInt(numero) < 10) {
      return `0${numero}`
    } else {
      return numero
    }
  }

  const manejarCrearNuevaOrden = () => {
    history.go(0)
  }
  const manejarVolverAOrdenes = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/ordenes')
  }
  const manejarCerrarModalConfirmacion = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/home')
  }

  const irAOrdenesMasivas = () => {
    history.push(RUTAS_APLICACION.CARGA_MASIVA_ORDEN)
  }

  useEffect(() => {
    contextoOrden.setFormularioOrden({usuario: {}})
  }, [])

  return (
    <div className="w-full pt-10 pb-10">
      <div className="container mx-auto flex justify-center">
        <div className="w-full flex">
          <div className="w-1/2 flex justify-end">
            <h4
              className="text-center text-cendiatra text-3xl font-bold"
              style={{paddingLeft: 235}}
            >
              CREAR ORDEN
            </h4>
          </div>

          <div className="w-1/2 flex justify-end -ml-44">
            <BotonSimple
              texto={'Cargar Órdenes Masivamente'}
              estilosBoton={`w-64 h-12 p-1.5 gap-2 flex items-center justify-center rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white  font-bold text-14px mx-25px`}
              funcion={irAOrdenesMasivas}
              icono={descargarPlantilla}
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="w-full">
          <FormularioOrden
            manejarEnviar={manejarEnviar}
            manejarGuardar={manejarGuardar}
          />
        </div>
      </div>
    </div>
  )
}

export default CrearOrden
