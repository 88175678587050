import React, {useEffect, useState} from 'react'
import TablaDinamica from '../TablaDinamica'
import {
  EncabezadoFondo,
  CeldaTextoConBoton,
  CeldaTextoEstilosEditables,
  CeldaFormatoDinero,
} from '../Tablas'
import {
  ObtenerMovimientosPorEmpresaId,
  ObtenerMovimientosUltimoMesPorEmpresaId,
} from '../../microservicios'
import {useMsal} from '@azure/msal-react'
import {useHistory} from 'react-router-dom'
import {BotonConImagen, ExportarCSVMovimientos} from '../Botones'
import moment from 'moment'
import IconoVisualizar from '../../imagenes/iconoVisualizar.svg'
import IconoDescargar from '../../imagenes/botonDescargar.svg'
import {TIPOS_MOVIMIENTO} from '../../constantes'

const TablaMovimientosYDetalles = () => {
  const headers = [
    {label: 'Fecha', key: 'fecha'},
    {label: 'Valor', key: 'valor'},
    {label: 'Detalle', key: 'detalle'},
    {label: '', key: 'urlReciboCaja'},
    {label: 'Número Atención', key: 'atencionId'},
  ]

  const history = useHistory()

  const {accounts} = useMsal()
  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const [datos, setDatos] = useState([])
  const [datosCsv, setDatosCsv] = useState([])

  useEffect(() => {
    const ObtenerDatosCuenta = async () => {
      try {
        const response = await ObtenerMovimientosPorEmpresaId(idEmpresa)
        if (response.status === 204) {
          return
        }
        const datosPromesas = response.data.map(async (elemento) => {
          return {
            fecha: moment(elemento.fecha).format('DD/MM/YYYY'),
            valor: elemento.valor,
            tipoMovimiento: elemento.tipoMovimiento,
            detalle: elemento.detalle,
            atencionId: elemento.atencionId ? elemento.atencionId : '',
            urlReciboCaja: elemento.urlReciboCaja,
          }
        })

        const datos = await Promise.all(datosPromesas)
        setDatos(datos)
      } catch (error) {
        console.error(error)
      }
    }

    ObtenerDatosCuenta()
    manejarObtenerDatosExportar()
  }, [])

  const verResultadoPaciente = (id) => {
    history.push('/resultadospaciente', {state: {atencionId: id}})
  }

  const manejarObtenerDatosExportar = async () => {
    try {
      const response = await ObtenerMovimientosUltimoMesPorEmpresaId(idEmpresa)
      if (response.status === 204) {
        return
      }
      const datosPromesas = response.data.map(async (elemento) => ({
        fecha: moment(elemento.fecha).format('DD/MM/YYYY'),
        valor: elemento.valor,
        tipoMovimiento: elemento.tipoMovimiento,
        detalle: elemento.detalle,
        atencionId: elemento.atencionId,
      }))
      setDatosCsv(await Promise.all(datosPromesas))
    } catch (error) {
      console.error(error)
    }
  }

  const imprimirReciboDeCaja = (urlRecibo) => {
    if (urlRecibo) {
      var win = window.open(urlRecibo, '_blank')
      win.focus()
    }
  }

  const columns = React.useMemo(() => [
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'FECHA'}
          estilosEncabezado={'bg-white text-cendiatra font-bold text-17px'}
        />
      ),
      accessor: 'fecha',
      className: 'w-3/12',
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'VALOR'}
          estilosEncabezado={'bg-white text-cendiatra font-bold text-17px'}
        />
      ),
      accessor: 'valor',
      className: 'w-3/12',
      Cell: (row) => (
        <CeldaFormatoDinero
          estilos={` font-bold flex justify-center items-center text-center pointer-events-none ${
            row.row.original.tipoMovimiento === 2
              ? 'text-cendiatra-rojo-1 '
              : 'text-cendiatra'
          }`}
          valor={row.row.original.valor}
        />
      ),
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'DETALLE'}
          estilosEncabezado={'bg-white text-cendiatra font-bold text-17px'}
        />
      ),
      accessor: 'detalle',
      className: 'w-3/12',
      Cell: (row) => (
        <CeldaTextoEstilosEditables
          texto={row?.row?.original?.detalle}
          estilos={'w-full flex justify-center items-center'}
          imagen={IconoDescargar}
        />
      ),
    },
    {
      accessor: 'urlReciboCaja',
      className: 'w-10 ml-2',
      Cell: (row) => (
        <>
          {row?.row?.original?.urlReciboCaja ? (
            <BotonConImagen
              funcionAEjecutar={() => {
                imprimirReciboDeCaja(row.row.original.urlReciboCaja)
              }}
              estilos={'w-full flex justify-center items-center'}
              imagen={IconoDescargar}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={'NÚMERO DE ATENCIÓN'}
          estilosEncabezado={'bg-white text-cendiatra font-bold text-17px'}
        />
      ),
      accessor: 'atencionId',
      className: 'w-3/12',
      Cell: (row) => (
        <CeldaTextoConBoton
          texto={row.row.original.atencionId}
          mostrarImagen={
            row.row.original.tipoMovimiento === TIPOS_MOVIMIENTO.DESCUENTO &&
            row.row.original.atencionId
          }
          funcion={() => verResultadoPaciente(row.row.original.atencionId)}
          estilos={'w-full flex justify-center items-center'}
          imagen={IconoVisualizar}
        />
      ),
    },
  ])

  return (
    <>
      <div className="w-full flex justify-center items-center">
        <TablaDinamica
          columns={columns}
          data={datos}
          fondoTabla={'bg-white'}
          paginacion={true}
          bordeInferior={''}
        />
      </div>
      <div className="w-full flex justify-end items-center">
        <div className="w-4/12 fles justify-center items-center -mt-28">
          <ExportarCSVMovimientos
            encabezados={headers}
            obtenerDatos={datosCsv}
            nombreArchivo={'movimientosYdetalles.csv'}
            texto={'Exportar Datos Del Último Mes'}
          />
        </div>
      </div>
    </>
  )
}

export default TablaMovimientosYDetalles
